import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    SelectChangeEvent,
    FormControlLabel,
    Checkbox,
    Button
} from '@mui/material';
import { apiUploadFile } from '../utils';
import ColorPicker from './color/ColorPicker';

interface QRBasicFormProps {
    initialState: QRBasicFormState;
    onChange: (state: QRBasicFormState) => void;
}

// TODO go2qr-lib
export enum QRRenderShape {
    None = "None",
    Square = "Square",
    Circle = "Circle",
    Diamond = "Diamond",
    Hexagon = "Hexagon"
}

export interface QRBasicFormState {
    backgroundFill: boolean;
    backgroundColor: string;
    moduleShape: QRRenderShape;
    moduleColorDark: string;
    moduleColorLight: string;
    finderShape: QRRenderShape;
    finderColorDark: string;
    finderColorLight: string;
    alignmentShape: QRRenderShape;
    alignmentColorDark: string;
    alignmentColorLight: string;
    useSafeCenter: boolean;
    centerImage: string | null;
}

const apiUrl = process.env.REACT_APP_GO2WS_API_URL;

const QRBasicForm: React.FC<QRBasicFormProps> = ({ initialState, onChange }) => {
    const [state, setState] = useState<QRBasicFormState>(initialState);
    // Used for deferred state updates, was having performance with frequent state updates like when draging color pickers!
    const stateRef = useRef<QRBasicFormState>(state);

    useEffect(() => {
        onChange(state);
        stateRef.current = { ...state };
    }, [state]);

    const handleInputChange = (key: keyof QRBasicFormState, defferUpdate: boolean = false) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
        if (defferUpdate) {
            stateRef.current = {
                ...stateRef.current,
                [key]: value,
            }
        } else {
            setState((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    const handleStateUpdate = () => {
        setState((state) => ({
            ...stateRef.current
        }));
    };

    const handleSelectChange = (key: keyof QRBasicFormState) => (event: SelectChangeEvent<QRRenderShape>) => {
        const value = event.target.value as QRRenderShape;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleImageChange = (key: keyof QRBasicFormState) => async (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const file = target.files ? target.files[0] : null;
        if (file) {
            try {
                const data = await apiUploadFile(apiUrl + '/user/image', file);
                setState((prevState) => ({
                    ...prevState,
                    [key]: data.id,
                }));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleColorPickerChange = (key: keyof QRBasicFormState) => async (color: string) => {
        setState((prevState) => ({
            ...prevState,
            [key]: color,
        }));
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.useSafeCenter}
                                onChange={handleInputChange('useSafeCenter')}
                            />
                        }
                        label="Use Safe Center"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin={'normal'}>
                        <Button variant="contained" component="label">
                            Center Image
                            <input type="file" hidden onChange={handleImageChange('centerImage')} />
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Background Color"
                        value={state.backgroundColor}
                        onChange={handleColorPickerChange('backgroundColor')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.backgroundFill}
                                onChange={handleInputChange('backgroundFill')}
                            />
                        }
                        label="Background Fill"
                    />
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Module Shape</InputLabel>
                <Select value={state.moduleShape} onChange={handleSelectChange('moduleShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Module Color Dark"
                        value={state.moduleColorDark}
                        type='dark'
                        onChange={handleColorPickerChange('moduleColorDark')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Module Color Light"
                        value={state.moduleColorLight}
                        type='light'
                        onChange={handleColorPickerChange('moduleColorLight')}
                    />
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Finder Shape</InputLabel>
                <Select value={state.finderShape} onChange={handleSelectChange('finderShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Finder Color Dark"
                        value={state.finderColorDark}
                        type='dark'
                        onChange={handleColorPickerChange('finderColorDark')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Finder Color Light"
                        value={state.finderColorLight}
                        type='light'
                        onChange={handleColorPickerChange('finderColorLight')}
                    />
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Alignment Shape</InputLabel>
                <Select value={state.alignmentShape} onChange={handleSelectChange('alignmentShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Alignment Color Dark"
                        value={state.alignmentColorDark}
                        type='dark'
                        onChange={handleColorPickerChange('alignmentColorDark')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Alignment Color Light"
                        value={state.alignmentColorLight}
                        type='light'
                        onChange={handleColorPickerChange('alignmentColorLight')}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default QRBasicForm;
