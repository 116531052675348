import React, { useState } from 'react';
import { IconButton, Drawer, List, ListItem, ListItemText, ListItemButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import Logo from './Logo';


const accountUrl = process.env.REACT_APP_GO2WS_ACCOUNT_URL as string;
const docsUrl = process.env.REACT_APP_GO2WS_DOCS_URL as string;

const SiteMenu = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: 'Home', path: '/', target: '_self' },
        { text: 'About', path: '/about', target: '_self' },
        { text: 'Account', path: accountUrl, target: '_blank' },
        { text: 'Documentation', path: docsUrl, target: '_blank' },
        { text: 'Sign Up', path: '/signup', target: '_self' }
    ];

    return (
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <List>
                        <ListItem>
                            <Logo size="32px" />
                        </ListItem>
                        {menuItems.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton component={Link} to={item.path} target={item.target} >
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

export default SiteMenu;
