import React, { ReactNode, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';

interface FAQItem {
    question: string;
    answer: string;
}

const ScreenAbout = () => {
    const navigate = useNavigate();
    const [faqItems, setFaqItems] = useState<FAQItem[]>([]);
    const [expanded, setExpanded] = useState<number | false>(false);

    const extractPageContent = () => {
        // Fetch page contnet from DOM see public/index.html
        const faqContentElement = document.getElementById('page-faq');
        if (faqContentElement) {
            const faqElements = Array.from(faqContentElement.querySelectorAll('li')).map(li => {
                const question = li.querySelector('strong')?.textContent || '';
                const answer = li.querySelector('p')?.textContent || '';
                return { question, answer };
            });
            setFaqItems(faqElements);
        }
    }

    useEffect(() => {
        extractPageContent();
    }, []);

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const insertLogo = (content: string): ReactNode => {
        const parts = content.split('Go2.ws');
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < parts.length - 1 && <Logo />}
            </React.Fragment>
        ));
    };

    return (
        <Box mt={33} textAlign="center">
            <Box component="header">
                <Logo size="90px" onClick={() => navigate('/')} />
            </Box>
            <Typography variant="caption">The goto website for stylish QR codes and short URLs.</Typography>
            <Container maxWidth="md">
                <Typography variant="h4" gutterBottom>
                    Frequently Asked Questions
                </Typography>
                {faqItems.map((faq, index) => (
                    <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography>{insertLogo(faq.question)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography textAlign={'left'} variant="body2">
                                {insertLogo(faq.answer)}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </Box>
    );
};

export default ScreenAbout;