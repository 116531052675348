import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { ColorRGB, ColorUtils } from './ColorUtils';

interface Color2DGradientProps {
    cornerColors: [ColorRGB, ColorRGB, ColorRGB, ColorRGB]; // Top-left, Top-right, Bottom-left, Bottom-right
    color: ColorRGB; // Current color to calculate initial pointer position
    onChange: (color: ColorRGB) => void;
    label?: string; // Optional label
    fullWidth?: boolean;
    margin?: 'dense' | 'normal' | 'none';
}

const Color2DGradient: React.FC<Color2DGradientProps> = ({
    cornerColors,
    color,
    onChange,
    label,
    fullWidth = true,
    margin = 'normal',
}) => {
    const [pointer, setPointer] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState<boolean>(false);

    // TODO is there a way not to brute force this!?
    const colorToXY = (color: ColorRGB): { x: number; y: number } => {
        const precision = 127;
        let minDistance = Number.MAX_VALUE, nearX = 0, nearY = 0;
        for (let x = 0; x < precision; x++) {
            for (let y = 0; y < precision; y++) {
                const c = xyToColor(x / precision, y / precision);
                const r = color.r - c.r;
                const g = color.g - c.g;
                const b = color.b - c.b;
                const distance = r * r + g * g + b * b;
                if (distance < minDistance) {
                    minDistance = distance;
                    nearX = x; nearY = y;
                }
            }
        }
        return { x: nearX / precision, y: nearY / precision };
    };

    const xyToColor = (x: number, y: number): ColorRGB => {
        const [C00, C10, C01, C11] = cornerColors;
        const topColor = interpolateColor(C00, C10, x);
        const bottomColor = interpolateColor(C01, C11, x);
        return interpolateColor(topColor, bottomColor, y);
    };

    const interpolateColor = (color1: ColorRGB, color2: ColorRGB, factor: number): ColorRGB => {
        const r = Math.round(color1.r + (color2.r - color1.r) * factor);
        const g = Math.round(color1.g + (color2.g - color1.g) * factor);
        const b = Math.round(color1.b + (color2.b - color1.b) * factor);
        return { r, g, b };
    };

    // Handle pointer movement
    const handlePointerMove = useCallback(
        (normalizedX: number, normalizedY: number) => {
            const clampedX = Math.max(0, Math.min(1, normalizedX));
            const clampedY = Math.max(0, Math.min(1, normalizedY));
            setPointer({ x: clampedX, y: clampedY });
            const newColor = xyToColor(clampedX, clampedY);
            onChange(newColor);
        },
        [onChange, cornerColors]
    );

    // Handle the start of the drag
    const handleStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        setIsDragging(true);
        const rect = (e.target as HTMLDivElement).getBoundingClientRect();
        const x = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        const y = 'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;
        handlePointerMove(x / rect.width, y / rect.height);
    };

    // Handle the movement during the drag
    const handleMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        const rect = (e.target as HTMLDivElement).getBoundingClientRect();
        const x = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        const y = 'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;
        handlePointerMove(x / rect.width, y / rect.height);
    };

    // Handle the end of the drag
    const handleEnd = () => {
        setIsDragging(false);
    };

    // Update pointer position when color changes
    useEffect(() => {
        const { x, y } = colorToXY(color);
        setPointer({ x, y });
    }, [color, cornerColors]);

    const topLeft = ColorUtils.rgbToHex(cornerColors[0]);
    const topRight = ColorUtils.rgbToHex(cornerColors[1]);
    const bottomLeft = ColorUtils.rgbToHex(cornerColors[2]);
    const bottomRight = ColorUtils.rgbToHex(cornerColors[3]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: fullWidth ? '100%' : 'auto',
                marginBottom: margin === 'normal' ? '16px' : margin === 'dense' ? '8px' : '0px',
            }}
        >
            {label && (
                <Typography
                    variant="caption"
                    sx={{ marginBottom: '4px', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                    {label}
                </Typography>
            )}
            <Box
                id="color-2d-gradient"
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '1/0.333',
                    maxHeight: '255px',
                    borderRadius: 1,
                    cursor: 'crosshair',
                    overflow: 'hidden',
                }}
                onMouseDown={handleStart}
                onMouseMove={handleMove}
                onMouseUp={handleEnd}
                onMouseLeave={handleEnd}
                onTouchStart={handleStart}
                onTouchMove={handleMove}
                onTouchEnd={handleEnd}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: `linear-gradient(to right, ${topLeft}, ${topRight})`,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: `linear-gradient(to right, ${bottomLeft}, ${bottomRight})`,
                        maskImage: 'linear-gradient(to bottom, transparent, black)',
                        maskSize: '100% 100%',
                        maskRepeat: 'no-repeat',
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: `${pointer.y * 100}%`,
                        left: `${pointer.x * 100}%`,
                        transform: 'translate(-50%, -50%)',
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        border: '2px solid white',
                        boxShadow: '0 0 0 1px rgba(0,0,0,0.5)',
                        pointerEvents: 'none',
                    }}
                />
            </Box>
        </Box>
    );
};

export default Color2DGradient;
