import React, { useState, useEffect, useRef } from 'react';
import { TextField, FormControl, Button, Grid, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { apiUploadFile } from '../utils';
import { QRRenderShape } from './QRBasicForm';
import ColorPicker from './color/ColorPicker';

interface QRImageFormProps {
    initialState: QRImageFormState;
    onChange: (state: QRImageFormState) => void;
    onImageChange?: (file: File) => void;
    onImageLoaded?: (imageId: string) => void;
}

export interface QRImageFormState {
    backgroundColor: string;
    backgroundFill: boolean;
    useQuietZone: boolean;
    image: string;
    finderShape: QRRenderShape;
    finderColorDark: string;
    finderColorLight: string;
    alignmentShape: QRRenderShape;
    alignmentColorDark: string;
    alignmentColorLight: string;
}

const apiUrl = process.env.REACT_APP_GO2WS_API_URL;

const QRImageForm: React.FC<QRImageFormProps> = ({ initialState, onChange, onImageChange, onImageLoaded }) => {
    const [state, setState] = useState<QRImageFormState>(initialState);
    // Used for deferred state updates, was having performance with frequent state updates like when draging color pickers!
    const stateRef = useRef<QRImageFormState>(state);

    useEffect(() => {
        onChange(state);
        stateRef.current = { ...state };
    }, [state]);

    const handleInputChange = (key: keyof QRImageFormState, defferUpdate: boolean = false) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
        if (defferUpdate) {
            stateRef.current = {
                ...stateRef.current,
                [key]: value,
            }
        } else {
            setState((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    const handleImageChange = (key: keyof QRImageFormState) => async (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const file = target.files ? target.files[0] : null;
        if (file) {
            try {
                if (onImageChange) {
                    onImageChange(file);
                }
                const data = await apiUploadFile(apiUrl + '/user/image', file);
                setState((prevState) => ({
                    ...prevState,
                    [key]: data.id,
                }));
                if (onImageLoaded) {
                    onImageLoaded(data.id);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleStateUpdate = () => {
        setState((state) => ({
            ...stateRef.current
        }));
    };

    const handleSelectChange = (key: keyof QRImageFormState) => (event: SelectChangeEvent<QRRenderShape>) => {
        const value = event.target.value as QRRenderShape;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleColorPickerChange = (key: keyof QRImageFormState) => async (color: string) => {
        setState((prevState) => ({
            ...prevState,
            [key]: color,
        }));
    };


    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Background Color"
                        value={state.backgroundColor}
                        onChange={handleColorPickerChange('backgroundColor')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.backgroundFill}
                                onChange={handleInputChange('backgroundFill')}
                            />
                        }
                        label="Background Fill"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.useQuietZone}
                                onChange={handleInputChange('useQuietZone')}
                            />
                        }
                        label="Use Quiet Zone"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin={'normal'}>
                        <Button variant="contained" component="label">
                            Upload Image
                            <input type="file" hidden onChange={handleImageChange('image')} />
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Finder Shape</InputLabel>
                <Select value={state.finderShape} onChange={handleSelectChange('finderShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Finder Color Dark"
                        value={state.finderColorDark}
                        type='dark'
                        onChange={handleColorPickerChange('finderColorDark')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Finder Color Light"
                        value={state.finderColorLight}
                        type='light'
                        onChange={handleColorPickerChange('finderColorLight')}
                    />
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Alignment Shape</InputLabel>
                <Select value={state.alignmentShape} onChange={handleSelectChange('alignmentShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Alignment Color Dark"
                        value={state.alignmentColorDark}
                        type='dark'
                        onChange={handleColorPickerChange('alignmentColorDark')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Alignment Color Light"
                        value={state.alignmentColorLight}
                        type='light'
                        onChange={handleColorPickerChange('alignmentColorLight')}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default QRImageForm;
