import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';

interface ColorHueProps {
    hue: number; // Hue value from 0 to 360
    onChange: (hue: number) => void;
    label?: string; // Optional label
    fullWidth?: boolean;
    margin?: 'dense' | 'normal' | 'none';
}

const ColorHue: React.FC<ColorHueProps> = ({
    hue,
    onChange,
    label,
    fullWidth = true,
    margin = 'normal',
}) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);

    // Handle hue change based on slider movement
    const handleHueChange = useCallback(
        (position: number, width: number) => {
            const newHue = Math.max(0, Math.min(360, (position / width) * 360));
            onChange(newHue);
        },
        [onChange]
    );

    // Handle the start of the drag
    const handleStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        setIsDragging(true);
        const rect = (e.target as HTMLDivElement).getBoundingClientRect();
        const position = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        handleHueChange(position, rect.width);
    };

    // Handle the movement during the drag
    const handleMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        if (!isDragging) return;

        const rect = (e.target as HTMLDivElement).getBoundingClientRect();
        const position = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        handleHueChange(position, rect.width);
    };

    // Handle the end of the drag
    const handleEnd = () => {
        setIsDragging(false);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: fullWidth ? '100%' : 'auto',
                marginBottom: margin === 'normal' ? '16px' : margin === 'dense' ? '8px' : '0px',
            }}
        >
            {label && (
                <Typography
                    variant="caption"
                    sx={{ marginBottom: '4px', display: 'block', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                    {label}
                </Typography>
            )}
            <Box
                sx={{
                    position: 'relative',
                    height: '20px',
                    borderRadius: 1,
                    cursor: 'pointer',
                    background: `linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000)`,
                }}
                onMouseDown={handleStart}
                onMouseMove={handleMove}
                onMouseUp={handleEnd}
                onMouseLeave={handleEnd}
                onTouchStart={handleStart}
                onTouchMove={handleMove}
                onTouchEnd={handleEnd}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        left: `${(hue / 360) * 100}%`,
                        top: 0,
                        width: '4px',
                        height: '100%',
                        backgroundColor: '#fff',
                        borderRadius: 1,
                        transform: 'translateX(-50%)',
                        pointerEvents: 'none',
                    }}
                />
            </Box>
        </Box>
    );
};

export default ColorHue;
