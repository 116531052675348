import React, { useState, useEffect } from 'react';
import { Slider, TextField, List, ListItem, Typography, Box, FormControl } from '@mui/material';

interface ArrayManagerProps<T> {
    value?: T[];
    maxItems?: number;
    defaultValue?: T;
    onChange: (items: T[]) => void;
    itemType?: string;
    label?: string;
    itemLabel?: string;
}

const ArrayManager = <T extends string | number | boolean>({
    value = [],
    maxItems = 8,
    defaultValue,
    onChange,
    itemType = 'text',
    label = 'Number of Items',
    itemLabel = 'Item'
}: ArrayManagerProps<T>) => {
    const [items, setItems] = useState<T[]>(value);

    useEffect(() => {
        if (Array.isArray(value)) {
            setItems(value);
        }
    }, [value]);

    const handleNumItemsChange = (event: Event, value: number | number[]) => {
        const count = value as number;
        const newItems = count > items.length ? [...items] : items.slice(0, count);
        if (count > items.length) {
            const newItem = items.length === 0 ? defaultValue as T : newItems[newItems.length - 1];
            while (newItems.length < count) newItems.push(newItem);
        }
        setItems(newItems);
        onChange(newItems);
    };

    const handleItemChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        items[index] = event.target.value as T;
        onChange(items);
    };

    return (
        <Box>
            <FormControl fullWidth margin="normal">
                <Typography>{label}</Typography>
                <Slider
                    value={items.length}
                    onChange={handleNumItemsChange}
                    min={0}
                    max={maxItems}
                    step={1}
                    marks
                    valueLabelDisplay="auto"
                />
            </FormControl>
            <List>
                {items.map((item, index) => (
                    <ListItem key={index}>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                type={itemType}
                                label={`${itemLabel} ${index + 1}`}
                                value={item}
                                onChange={handleItemChange(index)}
                                variant="outlined"
                            />
                        </FormControl>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ArrayManager;