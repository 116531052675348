import React, { useState } from 'react';
import { Popover, TextField, FormControl, Box, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ColorHue from './ColorHue';
import ColorSwatch from './ColorSwatch';
import { ColorHSL, ColorRGB, ColorUtils } from './ColorUtils';
import Color2DGradient from './Color2DGradient';

interface ColorPickerProps {
    label: string;
    value: string;
    type?: 'standard' | 'light' | 'dark';
    onChange?: (color: string) => void;
    fullWidth?: boolean;
    margin?: 'dense' | 'normal' | 'none';
}

const ColorPicker: React.FC<ColorPickerProps> = ({ label, value, onChange, fullWidth, margin, type = 'standard' }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const initialColorHsl = ColorUtils.hexToHsl(value);

    const [selectedColor, setSelectedColor] = useState<ColorHSL>(initialColorHsl);

    const presetColors = [
        '#000000', '#545454', '#a8a8a8', '#ffffff',
        '#fe0000', '#803400', '#fe6a00', '#401f00',
        '#804000', '#806b00', '#ffd800', '#017f01',
        '#00ff01', '#017f7e', '#01ffff', '#0094fe',
        '#00497e', '#0026ff', '#001280', '#590080',
        '#b100fe', '#7f0037', '#ff006e', '#800001'
    ];

    const handleChange = (colorHex: string) => {
        if (onChange) onChange(colorHex);
    };

    // Function to update the selected color from swatch
    const handleHexChange = (hexColor: string) => {
        const hslColor = ColorUtils.hexToHsl(hexColor);
        setSelectedColor(hslColor);
    };

    // Function to handle hue change
    const handleHueChange = (hue: number) => {
        setSelectedColor((prevColor: ColorHSL) => ({
            ...prevColor,
            h: hue,
        }));
    };

    const handleHSLChange = (color: ColorHSL) => {
        setSelectedColor(color);
    };

    const handleRGBChange = (color: ColorRGB) => {
        setSelectedColor(ColorUtils.rgbToHsl(color));
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        handleChange(ColorUtils.hslToHex(selectedColor));
    };

    const open = Boolean(anchorEl);
    const id = open ? 'color-picker-popover' : undefined;

    const colorRGB = ColorUtils.hslToRgb(selectedColor);
    const colorHex = ColorUtils.hslToHex(selectedColor);

    const getSatuationColors = (color: ColorHSL): [ColorRGB, ColorRGB, ColorRGB, ColorRGB] => {
        // [topleft, topTight, bottomLeft, bottomRight]
        switch (type) {
            case 'dark':
                return [
                    ColorUtils.hslToRgb({ h: color.h, s: 0, l: 50 }),
                    ColorUtils.hslToRgb({ h: color.h, s: 100, l: 50 }),
                    ColorUtils.hexToRgb('#000000'),
                    ColorUtils.hslToRgb({ h: color.h, s: 100, l: 0 })
                ];
            case 'light':
                return [
                    ColorUtils.hexToRgb('#FFFFFF'),
                    ColorUtils.hslToRgb({ h: color.h, s: 100, l: 70 }),
                    ColorUtils.hslToRgb({ h: color.h, s: 0, l: 70 }),
                    ColorUtils.hslToRgb({ h: color.h, s: 70, l: 70 })
                ];
        }
        // standard
        return [
            ColorUtils.hexToRgb('#FFFFFF'),
            ColorUtils.hslToRgb({ h: color.h, s: 100, l: 50 }),
            ColorUtils.hexToRgb('#000000'),
            ColorUtils.hslToRgb({ h: color.h, s: 100, l: 0 })
        ];
    }
    const saturationColors = getSatuationColors(selectedColor);

    return (
        <FormControl fullWidth={fullWidth} margin={margin}>
            <TextField
                label={label}
                value={value}
                onClick={handleClick}
                variant="outlined"
                fullWidth={fullWidth}
                InputProps={{
                    readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                style={{ cursor: 'pointer' }}
                type="color"
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ position: 'relative', padding: 2, minWidth: '300px' }}>

                    <Box sx={{ marginBottom: 2 }}>
                        <Color2DGradient
                            cornerColors={saturationColors}
                            color={colorRGB}
                            onChange={handleRGBChange}
                            label="Saturation"
                            fullWidth
                            margin="normal"
                        />
                    </Box>

                    <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleClose} size="small">
                        <CloseIcon />
                    </IconButton>

                    <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
                        <Grid item xs={10} sm={11}>
                            <ColorHue
                                hue={selectedColor.h}
                                onChange={handleHueChange}
                                label="Hue"
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <ColorSwatch
                                color={colorHex}
                                onClick={handleClose}
                                size={40}
                                borderColor="#000" // Highlight the current color
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Hex"
                                value={colorHex}
                                onChange={(e) => handleHexChange(e.target.value)}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField
                                label="R"
                                value={colorRGB.r}
                                onChange={(e) => {
                                    const rgb = ColorUtils.hslToRgb(selectedColor);
                                    rgb.r = Math.max(0, Math.min(255, parseInt(e.target.value) || 0));
                                    handleRGBChange(rgb);
                                }}
                                variant="outlined"
                                fullWidth
                                inputProps={{ type: 'number', min: 0, max: 255 }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField
                                label="G"
                                value={colorRGB.g}
                                onChange={(e) => {
                                    const rgb = ColorUtils.hslToRgb(selectedColor);
                                    rgb.g = Math.max(0, Math.min(255, parseInt(e.target.value) || 0));
                                    handleRGBChange(rgb);
                                }}
                                variant="outlined"
                                fullWidth
                                inputProps={{ type: 'number', min: 0, max: 255 }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField
                                label="B"
                                value={colorRGB.b}
                                onChange={(e) => {
                                    const rgb = ColorUtils.hslToRgb(selectedColor);
                                    rgb.b = Math.max(0, Math.min(255, parseInt(e.target.value) || 0));
                                    handleRGBChange(rgb);
                                }}
                                variant="outlined"
                                fullWidth
                                inputProps={{ type: 'number', min: 0, max: 255 }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        {presetColors.map((color, index) => (
                            <Grid item xs={3} sm={2} md={1} key={index}>
                                <ColorSwatch
                                    color={color}
                                    onClick={handleHexChange}
                                    size={30} // Smaller size for better fit
                                    borderColor={color === ColorUtils.hslToHex(selectedColor) ? '#000' : 'transparent'} // Highlight if selected
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Popover>
        </FormControl>
    );
};

export default ColorPicker;
