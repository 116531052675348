import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { Box, Button, Container, FormControl, Grid, TextField, Typography } from '@mui/material';

const apiUrl = process.env.REACT_APP_GO2WS_API_URL;

const ScreenHome = () => {

    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        //console.log(process.env);
        //document.title = "Go2.ws URL Shortener";
    }, []);

    const handleShorten = async () => {
        try {
            const response = await axios.post(apiUrl + `/generate`, { url });
            navigate('/result', {
                state: {
                    shortId: response.data.id,
                    shortUrl: response.data.shortUrl,
                    originalUrl: url
                }
            });
        } catch (error: any) {
            console.log(error);
            setErrorMessage(error?.response?.data?.message || error.message || 'Error');
        }
    };

    const handleQRCode = async () => {
        try {
            const response = await axios.post(apiUrl + `/generate`, { url });
            navigate('/designer', {
                state: {
                    shortId: response.data.id,
                    shortUrl: response.data.shortUrl,
                    originalUrl: url
                }
            });
        } catch (error: any) {
            console.log(error);
            setErrorMessage(error?.response?.data?.message || error.message || 'Error');
        }
    }

    return (
        <Box mt={33} textAlign="center">
            <Box component="header">
                <Logo size="90px" />
            </Box>
            <Typography variant="caption">The goto website for stylish QR codes and short URLs.</Typography>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Enter a link to make a QR Code or Short URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="My URL"
                    variant="filled"
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                />
            </FormControl>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={handleQRCode}>
                            Make QR Code
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={handleShorten}>
                            Make Short URL
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ScreenHome;