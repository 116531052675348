import React from 'react';
import styles from './Logo.module.css';

interface LogoProps {
    size?: string;
    onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({ size, onClick }) => (
    <div
        className={styles.logo}
        style={{ fontSize: size, cursor: onClick ? 'pointer' : 'default' }}
        onClick={onClick}
    >
        Go<span className={styles.numberTwo}>2</span>.ws
    </div>
);

export default Logo;