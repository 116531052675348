import React, { useState, useEffect, useRef } from 'react';
import { Button, Select, MenuItem, FormControl, InputLabel, Slider, Typography, Box, TextField, Grid, CircularProgress, Container } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import QRBasicForm, { QRBasicFormState, QRRenderShape } from './QRBasicForm';
import QRImageForm, { QRImageFormState } from './QRImageForm';
import QRSnakeForm, { QRSnakeFormState } from './QRSnakeForm';
import Logo from './Logo';
import QRMondrianForm, { QRMondrianFormState } from './QRMondrianForm';
import QRWindForm, { QRWindFormState } from './QRWindForm';

const apiUrl = process.env.REACT_APP_GO2WS_API_URL;

enum QRType {
    Basic = "Basic",
    Snake = "Snake",
    Wind = "Wind",
    Mondrian = "Mondrian",
    //Blitter = "Blitter",
    Image = "Image"
}

enum QRErrorCorrection {
    Low = "low",
    Medium = "medium",
    Quartile = "quartile",
    High = "high"
}

const ScreenDesigner = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { shortId, shortUrl, originalUrl } = location.state;

    const [data, setData] = useState(originalUrl);
    const [type, setType] = useState(QRType.Basic);

    // Common QR Props    
    const [moduleSize, setModuleSize] = useState(8);
    const [quietZone, setQuietZone] = useState(4);
    const [errorCorrection, setErrorCorrection] = useState(QRErrorCorrection.Medium);
    const [qrCode, setQRCode] = useState("");
    const [isGeneratingQR, setIsGeneratingQR] = useState(false); // State to track loading

    // Basic QR Props    
    const [basicFormState, setBasicFormState] = useState<QRBasicFormState>({
        backgroundFill: true,
        backgroundColor: "#FFFFFF",
        moduleShape: QRRenderShape.Square,
        moduleColorDark: "#000000",
        moduleColorLight: "#FFFFFF",
        finderShape: QRRenderShape.Square,
        finderColorDark: "#000000",
        finderColorLight: "#FFFFFF",
        alignmentShape: QRRenderShape.Square,
        alignmentColorDark: "#000000",
        alignmentColorLight: "#FFFFFF",
        useSafeCenter: true,
        centerImage: null
    });
    // Image QR Props  
    const [imageFormState, setImageFormState] = useState<QRImageFormState>({
        backgroundColor: "#FFFFFF",
        backgroundFill: true,
        useQuietZone: false,
        image: "",
        finderShape: QRRenderShape.Circle,
        finderColorDark: "#000000",
        finderColorLight: "#FFFFFF",
        alignmentShape: QRRenderShape.Circle,
        alignmentColorDark: "#000000",
        alignmentColorLight: "#FFFFFF"
    });
    // Image QR Props  
    const [snakeFormState, setSnakeFormState] = useState<QRSnakeFormState>({
        backgroundFill: true,
        backgroundColor: "#FFFFFF",
        moduleColorDark: "#000000",
        moduleColorLight: "#FFFFFF",
        cornerSize: 1
    });
    // Mondrian QR Props  
    const [mondrianFormState, setMondrianFormState] = useState<QRMondrianFormState>({
        backgroundFill: true,
        backgroundColor: "#FFFFFF",
        modulePaletteDark: ['#101010', '#cb1901', '#002395'],
        modulePaletteLight: ['#FFFFFF', '#FFFFFF', '#d3e2e8', '#ffd549']
    });
    // Mondrian QR Props  
    const [windFormState, setWindFormState] = useState<QRWindFormState>({
        backgroundFill: true,
        backgroundColor: "#CCCCCC",
        moduleColorDark: "#000000",
        moduleColorLight: "#FFFFFF",
        strengthX: 0.2,
        strengthY: 1,
        direction: 45,
        randomDirection: false,
        finderShape: QRRenderShape.Square,
        finderColorDark: "#000000",
        finderColorLight: "#FFFFFF",
        alignmentShape: QRRenderShape.Square,
        alignmentColorDark: "#000000",
        alignmentColorLight: "#FFFFFF"
    });

    const DEBOUNCE_DELAY = 500;
    const qrRequestTimerId = useRef(0);
    const qrRequestComplete = useRef(true);

    useEffect(() => {
        if (qrRequestTimerId.current) {
            window.clearTimeout(qrRequestTimerId.current);
        }
        setIsGeneratingQR(true);
        qrRequestTimerId.current = window.setTimeout(handleQRDebouncedRequest, DEBOUNCE_DELAY);
    }, [
        type, basicFormState, imageFormState, snakeFormState, mondrianFormState, windFormState, moduleSize, quietZone, errorCorrection
    ]);

    const handleQRDebouncedRequest = async () => {
        // only one request at a time, wait some more
        if (!qrRequestComplete.current) {
            qrRequestTimerId.current = window.setTimeout(handleQRDebouncedRequest, DEBOUNCE_DELAY);
            return;
        }
        qrRequestComplete.current = false;
        try {
            await generateQRCode();
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
        qrRequestComplete.current = true;
        qrRequestTimerId.current = 0;
    };

    const parseAPIBufferError = (error: AxiosError): string => {
        if (error.response && error.response.status !== 200) {
            try {
                const decoder = new TextDecoder('utf-8');
                const responseText = decoder.decode(new Uint8Array(error.response.data as ArrayBuffer));
                const jsonResponse = JSON.parse(responseText);
                return jsonResponse.message || 'An error occurred';
            } catch (parseError) {
                return 'An unexpected error occurred';
            }
        } else if (error.message) {
            return error.message;
        } else {
            return 'An unknown error occurred';
        }
    };

    const generateQRCode = async () => {
        try {
            let request = {
                shortId, type, moduleSize, quietZone, errorCorrection, data
            };
            switch (type) {
                case QRType.Basic:
                    request = { ...request, ...basicFormState }
                    break;
                case QRType.Image:
                    request = { ...request, ...imageFormState }
                    break;
                case QRType.Snake:
                    request = { ...request, ...snakeFormState }
                    break;
                case QRType.Mondrian:
                    request = { ...request, ...mondrianFormState }
                    break;
                case QRType.Wind:
                    request = { ...request, ...windFormState }
                    break;
                default:
                    throw (new Error(`Unknown type '${type}'`))
            }
            // new binary response way
            const response = await axios.post(`${apiUrl}/qr/generate`, request, {
                headers: {
                    'Accept': 'image/png',
                    'Content-Type': 'application/json'
                },
                responseType: 'arraybuffer'
            });
            const blob = new Blob([response.data], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
            // clear before ui update
            setIsGeneratingQR(false);
            setQRCode(imageUrl);
        } catch (error) {
            const errorMessage = parseAPIBufferError(error as AxiosError);
            console.error('Error generating QR code: ' + errorMessage);
        }
    };

    const handleImageChange = (setter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            const target = event.target as HTMLInputElement;
            const file = target.files == null ? null : target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target) setter(e.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const downloadImage = () => {
        if (qrCode.length) {
            const link = document.createElement('a');
            link.href = qrCode;
            link.download = shortId + ".png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const childImageChnage = () => {
        setIsGeneratingQR(true);
    }

    const renderTypeSpecificFields = () => {
        switch (type) {
            case QRType.Basic:
                return <QRBasicForm initialState={basicFormState} onChange={setBasicFormState} />;
            case QRType.Image:
                return <QRImageForm initialState={imageFormState} onChange={setImageFormState} onImageChange={childImageChnage} />;
            case QRType.Snake:
                return <QRSnakeForm initialState={snakeFormState} onChange={setSnakeFormState} />;
            case QRType.Mondrian:
                return <QRMondrianForm initialState={mondrianFormState} onChange={setMondrianFormState} />;
            case QRType.Wind:
                return <QRWindForm initialState={windFormState} onChange={setWindFormState} />;
            default:
                return null;
        }
    };

    return (
        <Box display="flex" flexDirection="column" height="92vh" textAlign="center">
            <Box component="header">
                <Logo size="60px" onClick={() => navigate('/')} />
            </Box>
            <Typography variant="caption">QR Code Designer</Typography>
            <Box position="relative" className="style-scrollbar" maxHeight="33vh" overflow="auto">
                <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)', display: 'inline-block' }}>
                    <img src={qrCode} alt={data} style={{ display: 'block', verticalAlign: 'bottom' }} />
                </Box>
                {isGeneratingQR && (
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <Box className="style-scrollbar" flex="1" sx={{ overflowY: 'auto', overflowX: 'hidden', padding: '20px' }} >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="URL"
                                value={originalUrl}
                                inputProps={{ readOnly: true }}
                                variant="filled"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl margin="normal">
                            <Button variant="contained" color="primary" onClick={() => window.open(shortUrl, '_blank')}>
                                Visit
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl fullWidth margin={'normal'}>
                            <InputLabel>Type</InputLabel>
                            <Select value={type} onChange={(e) => setType(e.target.value as QRType)} >
                                {Object.keys(QRType).map((key) => (
                                    <MenuItem key={key} value={(QRType as any)[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin={'normal'}>
                            <InputLabel>Error Correction</InputLabel>
                            <Select value={errorCorrection} onChange={(e) => setErrorCorrection(e.target.value as QRErrorCorrection)}>
                                {Object.keys(QRErrorCorrection).map((key) => (
                                    <MenuItem key={key} value={(QRErrorCorrection as any)[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl fullWidth margin={'normal'}>
                            <Typography>Module Size</Typography>
                            <Slider
                                value={moduleSize}
                                onChange={(e, newValue) => setModuleSize(newValue as number)}
                                min={1} max={32} step={1}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin={'normal'}>
                            <Typography>Quiet Zone</Typography>
                            <Slider
                                value={quietZone}
                                onChange={(e, newValue) => setQuietZone(newValue as number)}
                                min={0} max={8} step={1}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {renderTypeSpecificFields()}
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl fullWidth margin={'normal'}>
                            <Button variant="contained" color="primary" onClick={generateQRCode} disabled={!isGeneratingQR}>
                                Generate
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin={'normal'}>
                            <Button variant="contained" color="primary" onClick={downloadImage} disabled={isGeneratingQR}>
                                Download
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ScreenDesigner;