import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    SelectChangeEvent,
    FormControlLabel,
    Checkbox,
    Typography,
    Slider
} from '@mui/material';
import { QRRenderShape } from './QRBasicForm';

interface QRWindFormProps {
    initialState: QRWindFormState;
    onChange: (state: QRWindFormState) => void;
}

export interface QRWindFormState {
    backgroundFill: boolean;
    backgroundColor: string;
    moduleColorDark: string;
    moduleColorLight: string;
    strengthX: number;
    strengthY: number;
    direction: number;
    randomDirection: boolean;
    finderShape: QRRenderShape;
    finderColorDark: string;
    finderColorLight: string;
    alignmentShape: QRRenderShape;
    alignmentColorDark: string;
    alignmentColorLight: string;
}

const QRWindForm: React.FC<QRWindFormProps> = ({ initialState, onChange }) => {
    const [state, setState] = useState<QRWindFormState>(initialState);

    useEffect(() => {
        onChange(state);
    }, [state]);

    const handleInputChange = (key: keyof QRWindFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleShapeSelectChange = (key: keyof QRWindFormState) => (event: SelectChangeEvent<QRRenderShape>) => {
        const value = event.target.value as QRRenderShape;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSliderChange = (key: keyof QRWindFormState) => (event: Event, value: number | number[]) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value as number,
        }));
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Background Color"
                            type="color"
                            value={state.backgroundColor}
                            onChange={handleInputChange('backgroundColor')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.backgroundFill}
                                onChange={handleInputChange('backgroundFill')}
                            />
                        }
                        label="Background Fill"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Module Color Dark"
                            type="color"
                            value={state.moduleColorDark}
                            onChange={handleInputChange('moduleColorDark')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Module Color Light"
                            type="color"
                            value={state.moduleColorLight}
                            onChange={handleInputChange('moduleColorLight')}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Finder Shape</InputLabel>
                <Select value={state.finderShape} onChange={handleShapeSelectChange('finderShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Finder Color Dark"
                            type="color"
                            value={state.finderColorDark}
                            onChange={handleInputChange('finderColorDark')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Finder Color Light"
                            type="color"
                            value={state.finderColorLight}
                            onChange={handleInputChange('finderColorLight')}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <Typography>Strength X</Typography>
                        <Slider
                            value={state.strengthX}
                            onChange={handleSliderChange('strengthX')}
                            min={0} max={1} step={0.1}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <Typography>Strength Y</Typography>
                        <Slider
                            value={state.strengthY}
                            onChange={handleSliderChange('strengthY')}
                            min={0} max={1} step={0.1}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <FormControl fullWidth margin="normal">
                        <Typography>Direction</Typography>
                        <Slider
                            value={state.direction}
                            onChange={handleSliderChange('direction')}
                            min={0} max={180} step={2.5}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.randomDirection}
                                onChange={handleInputChange('randomDirection')}
                            />
                        }
                        label="Random Direction"
                    />
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel>Alignment Shape</InputLabel>
                <Select value={state.alignmentShape} onChange={handleShapeSelectChange('alignmentShape')}>
                    {Object.keys(QRRenderShape).map((key) => (
                        <MenuItem key={key} value={(QRRenderShape as any)[key]}>
                            {(QRRenderShape as any)[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Alignment Color Dark"
                            type="color"
                            value={state.alignmentColorDark}
                            onChange={handleInputChange('alignmentColorDark')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Alignment Color Light"
                            type="color"
                            value={state.alignmentColorLight}
                            onChange={handleInputChange('alignmentColorLight')}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default QRWindForm;
