import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';

//const apiUrl = process.env.REACT_APP_GO2WS_API_URL;
const apiUrl = "https://api.go2.ws"

const ScreenSignUp = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        //console.log(process.env);
        //document.title = "Go2.ws URL Shortener";
    }, []);

    const handleSubmit = async () => {
        try {
            const response = await axios.post(apiUrl + `/account/signup`, { email, password });
            setErrorMessage("Success");
        } catch (error: any) {
            console.log(error);
            setErrorMessage(error?.response?.data?.message || error.message || 'Error');
        }
    };

    return (
        <Box mt={33} textAlign="center">
            <Box component="header">
                <Logo size="90px" />
            </Box>
            <Typography variant="caption">The goto website for stylish QR codes and short URLs.</Typography>
            <FormControl fullWidth margin="normal">
                <TextField
                    required
                    label="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="filled"
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    required
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="filled"
                    error={Boolean(errorMessage)}
                />
            </FormControl>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                            Go Home
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Register
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ScreenSignUp;