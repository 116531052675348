export type ColorHSL = { h: number; s: number; l: number }; // H: 0-360, S: 0-100, L: 0-100
export type ColorRGB = { r: number; g: number; b: number }; // R, G, B: 0-255

export class ColorUtils {
    // Convert RGB to HSL
    static rgbToHsl({ r, g, b }: ColorRGB): ColorHSL {
        const rNorm = r / 255;
        const gNorm = g / 255;
        const bNorm = b / 255;

        const max = Math.max(rNorm, gNorm, bNorm);
        const min = Math.min(rNorm, gNorm, bNorm);
        let h = 0,
            s = 0;
        const l = (max + min) / 2;

        if (max !== min) {
            const delta = max - min;
            s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
            switch (max) {
                case rNorm:
                    h = (gNorm - bNorm) / delta + (gNorm < bNorm ? 6 : 0);
                    break;
                case gNorm:
                    h = (bNorm - rNorm) / delta + 2;
                    break;
                case bNorm:
                    h = (rNorm - gNorm) / delta + 4;
                    break;
            }
            h *= 60;
        }

        return { h: Math.round(h), s: s * 100, l: l * 100 };
    }

    // Convert HSL to RGB
    static hslToRgb({ h, s, l }: ColorHSL): ColorRGB {
        const saturation = s / 100;
        const lightness = l / 100;

        if (saturation === 0) {
            const value = Math.round(lightness * 255);
            return { r: value, g: value, b: value };
        }

        const hue = h / 360;

        const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
        const p = 2 * lightness - q;

        const convert = (t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const r = Math.round(convert(hue + 1 / 3) * 255);
        const g = Math.round(convert(hue) * 255);
        const b = Math.round(convert(hue - 1 / 3) * 255);

        return { r, g, b };
    }

    // Convert hex to RGB
    static hexToRgb(hex: string): ColorRGB {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return { r, g, b };
    }

    // Convert RGB to Hex
    static rgbToHex({ r, g, b }: ColorRGB): string {
        const toHex = (c: number) => c.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    // Convert hex to HSL
    static hexToHsl(hex: string): ColorHSL {
        const rgb = this.hexToRgb(hex);
        return this.rgbToHsl(rgb);
    }

    // Convert HSL to Hex
    static hslToHex(color: ColorHSL): string {
        const rgb = this.hslToRgb(color);
        return this.rgbToHex(rgb);
    }

    static clampLuma(c: ColorRGB, lumaMin: number, lumaMax: number): ColorRGB {
        // Calculate the luminance of the color
        const calculateLuma = (r: number, g: number, b: number): number => {
            // Using the Rec. 709 formula for luminance
            return 0.2126 * r + 0.7152 * g + 0.0722 * b;
        };

        // Clamp a value between min and max
        const clamp = (value: number, min: number, max: number): number => {
            return Math.max(min, Math.min(max, value));
        };

        // Calculate the current luminance
        const currentLuma = calculateLuma(c.r, c.g, c.b);

        // If the current luminance is within the range, return the original color
        if (currentLuma >= lumaMin && currentLuma <= lumaMax) {
            return c;
        }

        // Calculate the scale factor to adjust the luminance
        const targetLuma = clamp(currentLuma, lumaMin, lumaMax);
        const scaleFactor = targetLuma / currentLuma;

        // Adjust the color components based on the scale factor
        const newR = clamp(c.r * scaleFactor, 0, 255);
        const newG = clamp(c.g * scaleFactor, 0, 255);
        const newB = clamp(c.b * scaleFactor, 0, 255);

        return { r: Math.round(newR), g: Math.round(newG), b: Math.round(newB) };
    }
}
