import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Logo from './Logo';
import { Button, FormControl, Box, Typography, Link } from '@mui/material';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_GO2WS_API_URL;

const ScreenGateway = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [hostname, setHostname] = useState('');
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUrl = async () => {
            try {
                const response = await axios.get(`${apiUrl}/short-urls/${id}`);
                setUrl(response.data.url);
                try {
                    const url = new URL(response.data.url);
                    setHostname(url.hostname);
                }
                catch (error) {
                    // should never happen try to recover
                    console.log(error);
                    setHostname(response.data.url);
                }
            } catch (err) {
                navigate("/error");
            }
            setLoading(false);
        };
        fetchUrl();
    }, [id]);

    if (loading) {
        return (
            <Box mt={33} textAlign="center">
                <Typography variant="h6">Loading...</Typography>
            </Box>
        );
    }

    return (
        <Box mt={33} textAlign="center">
            <Box component="header">
                <Logo size="90px" />
            </Box>
            <Typography variant="h5" paragraph>
                Hold Tight! You're on Your Way to...
            </Typography>
            <Typography variant="h4" paragraph>
                {hostname}
            </Typography>
            <Box>
                <Typography paragraph>
                    Welcome to <Link href="/" color="primary">Go2.ws</Link>, your go-to website for short URLs and QR codes.<br />
                    You’re about to head over to: <strong>{hostname}</strong>. Exciting, right?
                </Typography>
                <Typography paragraph>
                    <strong>But first, a quick question:</strong>
                </Typography>
                <Typography variant="h6" paragraph>
                    Do you trust the person, site, or QR code that sent you here?
                </Typography>
                <Typography paragraph>
                    At <Link href="/" color="primary">Go2.ws</Link>, we’re all about keeping things safe and secure while you zip around the web.<br />
                    This nifty gateway page is here to make sure everything’s on the up-and-up.<br />
                </Typography>
                <Box marginBlockEnd={2}>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={() => window.location.href = url}>
                            I'm Good to Go There
                        </Button>
                    </FormControl>
                </Box>
                <Typography paragraph>
                    Want to skip this step in the future? <Link href="/signup" color="primary">Sign up</Link> to create speedy direct links.
                </Typography>
            </Box>

            <Box mt={4}>
                <Typography variant="body2" color="textSecondary">
                    Just a heads-up: Go2.ws isn't responsible for what happens beyond this point.
                    <br />
                    <Link href="/" color="primary">
                        Give us a visit
                    </Link> and discover what Go2.ws can do for you!
                </Typography>
            </Box>
        </Box>
    );
};

export default ScreenGateway;
