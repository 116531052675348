import React, { useState, useEffect } from 'react';
import { TextField, FormControl, Grid, Checkbox, FormControlLabel, Slider, Typography } from '@mui/material';
import ArrayManager from './ArrayManager';

interface QRMondrianFormProps {
    initialState: QRMondrianFormState;
    onChange: (state: QRMondrianFormState) => void;
}

export interface QRMondrianFormState {
    backgroundFill: boolean;
    backgroundColor: string;
    modulePaletteDark: string[];
    modulePaletteLight: string[];
}

const QRMondrianForm: React.FC<QRMondrianFormProps> = ({ initialState, onChange }) => {
    const [state, setState] = useState<QRMondrianFormState>(initialState);

    useEffect(() => {
        onChange(state);
    }, [state]);

    const handleInputChange = (key: keyof QRMondrianFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleItemsChange = (key: keyof QRMondrianFormState) => (items: string[]) => {
        console.log("Changed items "+key);
        setState((prevState) => ({
            ...prevState,
            [key]: items,
        }));
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Background Color"
                            type="color"
                            value={state.backgroundColor}
                            onChange={handleInputChange('backgroundColor')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.backgroundFill}
                                onChange={handleInputChange('backgroundFill')}
                            />
                        }
                        label="Background Fill"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ArrayManager<string>
                        value={state.modulePaletteDark}
                        maxItems={10}
                        defaultValue="#000000"
                        onChange={handleItemsChange('modulePaletteDark')}
                        itemType="color"
                        label='Module Colors Dark'
                        itemLabel='Dark Color'
                    />
                </Grid>
                <Grid item xs={6}>
                    <ArrayManager<string>
                        value={state.modulePaletteLight}
                        maxItems={10}
                        defaultValue="#FFFFFF"
                        onChange={handleItemsChange('modulePaletteLight')}
                        itemType="color"
                        label='Module Colors Light'
                        itemLabel='Light Color'
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default QRMondrianForm;