import axios from "axios";

export type Color = [number, number, number];

export const hexToRgb = (color: string): Color => {
    color = color.replace(/^#/, '');
    // Handle shorthand hex colors (e.g., "#f0f" -> "#ff00ff")
    if (color.length === 3) {
        color = color.split('').map(char => char + char).join('');
    }
    const c = parseInt(color, 16);
    const r = (c >> 16) & 255;
    const g = (c >> 8) & 255;
    const b = c & 255;
    return [r, g, b];
};

export const luminance = (color: Color): number => {
    return 0.299 * (color[0] / 255.0) + 0.587 * (color[1] / 255.0) + 0.114 * (color[2] / 255.0);
};

export const lightColorSafe = (color: string): boolean => {
    return luminance(hexToRgb(color)) > 0.5;
}

export const darkColorSafe = (color: string): boolean => {
    return luminance(hexToRgb(color)) < 0.5;
}

export const apiUploadFile = async (endpointUrl: string, file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('filename', file.name);
    try {
        const response = await axios.post(endpointUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error file upload:', error);
        throw new Error('Api file upload failed');
    }
};