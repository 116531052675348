import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { Box, Button, Container, FormControl, Grid, TextField, Typography } from '@mui/material';

const apiUrl = process.env.REACT_APP_GO2WS_API_URL;

const ScreenHome = () => {

    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        //console.log(process.env);
        //document.title = "Go2.ws URL Shortener";
    }, []);

    return (
        <Box mt={33} textAlign="center">
            <Box component="header">
                <Logo size="90px" />
            </Box>
            <Typography variant="h4" gutterBottom>Uh-oh! We Can't Find That Page</Typography>
            <Box>
                <Typography gutterBottom>Sorry, we couldn't find the page you were looking for.</Typography>
                <Typography gutterBottom>But don't worry, why not create a cool short URL or a unique QR code instead?</Typography>
            </Box>
            <Box>
                <FormControl margin="normal">
                    <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                        Let's do it
                    </Button>
                </FormControl>
            </Box>
        </Box>
    );
};

export default ScreenHome;