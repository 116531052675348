import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { Button, FormControl, TextField, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography } from '@mui/material';

const ScreenResult = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { shortId, shortUrl, originalUrl } = location.state;
    const [dialogCopyOpen, setDialogCopyOpen] = useState(false);

    const handleCopyClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shortUrl);
            setDialogCopyOpen(true);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const handleDialogCopyClose = () => {
        setDialogCopyOpen(false);
    };

    const handleDesignQRCode = () => {
        navigate('/designer', { state: { shortId, shortUrl, originalUrl } });
    };

    return (
        <Box mt={33} textAlign="center">
            <Box component="header">
                <Logo size="90px" onClick={() => navigate('/')} />
            </Box>
            <Typography variant="caption">The goto website for stylish QR codes and short URLs.</Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Short URL"
                            value={shortUrl}
                            inputProps={{ readOnly: true }}
                            variant="filled"
                            onClick={handleCopyClipboard}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={() => window.open(shortUrl, '_blank')}>
                            Visit
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={handleDesignQRCode}>
                            Make QR Code
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl margin="normal">
                        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                            Make Another
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
            <Dialog open={dialogCopyOpen} onClose={handleDialogCopyClose}>
                <DialogTitle>URL Copied</DialogTitle>
                <DialogContent>
                    The short URL has been copied to your clipboard.
                </DialogContent>
                <Box display="flex" justifyContent="center">
                    <DialogActions>
                        <Button onClick={handleDialogCopyClose} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    );
};

export default ScreenResult;
