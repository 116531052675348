
export interface Point {
    x: number, y: number
}

export interface Target extends Point {
    attraction: number
}

export interface Boid extends Point {
    vx: number;
    vy: number;
    r: number;
}

export function rand(n?: number, max?: number): number {
    if (n === undefined) return Math.random();
    if (max === undefined) return Math.random() * n;
    return Math.random() * (max - n) + n;
}

export class Boids {
    public boids: Boid[] = [];

    private target: Target = { x: 0, y: 0, attraction: 0 };

    constructor(private width: number, private height: number) {

    }

    public init(count: number = 25) {
        for (let i = 0; i < count; i++) {
            this.boids.push({
                x: rand(window.innerWidth),
                y: rand(window.innerHeight),
                vx: rand(-1, 1),
                vy: rand(-1, 1),
                r: 0
            });
        }
    }

    public setTarget(x: number, y: number, attraction: number) {
        this.target.x = x;
        this.target.y = y;
        this.target.attraction = attraction;
    }

    public animate() {
        this.boids.forEach(boid => {
            let alignX = 0, alignY = 0, cohereX = 0, cohereY = 0, separateX = 0, separateY = 0;
            let total = 0;
            this.boids.forEach(other => {
                if (other !== boid) {
                    const dx = other.x - boid.x;
                    const dy = other.y - boid.y;
                    const dist = Math.sqrt(dx * dx + dy * dy);
                    if (dist > 0 && dist < 50) {
                        alignX += other.vx;
                        alignY += other.vy;
                        cohereX += dx;
                        cohereY += dy;
                        total++;
                    }
                    if (dist > 0 && dist < 150) {
                        separateX -= dx / dist;
                        separateY -= dy / dist;
                    }
                }
            });

            if (total > 0) {
                boid.vx += ((alignX / total - boid.vx) * 0.005 + (cohereX / total) * 0.01 + separateX * 0.3) + rand(-0.1, 0.1);
                boid.vy += ((alignY / total - boid.vy) * 0.005 + (cohereY / total) * 0.01 + separateY * 0.3) + rand(-0.1, 0.1);
            }

            boid.vx += (this.target.x - boid.x) / this.width * this.target.attraction;
            boid.vy += (this.target.y - boid.y) / this.height * this.target.attraction;
            this.target.attraction *= 0.9995;

            // Limit speed
            const speed = Math.sqrt(boid.vx * boid.vx + boid.vy * boid.vy);
            const maxSpeed = 4;
            if (speed > maxSpeed) {
                boid.vx = (boid.vx / speed) * maxSpeed;
                boid.vy = (boid.vy / speed) * maxSpeed;
            }
            // Update position and wrap around boundaries
            boid.x = (boid.x + boid.vx + this.width) % this.width;
            boid.y = (boid.y + boid.vy + this.height) % this.height;
            boid.r = Math.atan2(boid.vy, boid.vx);// + Math.PI / 2;
        });
    }

    public resize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }
}
