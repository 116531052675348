import React, { useState, useEffect } from 'react';
import { TextField, FormControl, Grid, Checkbox, FormControlLabel, Slider, Typography } from '@mui/material';
import ColorPicker from './color/ColorPicker';

interface QRBasicFormProps {
    initialState: QRSnakeFormState;
    onChange: (state: QRSnakeFormState) => void;
}

export interface QRSnakeFormState {
    backgroundFill: boolean;
    backgroundColor: string;
    moduleColorDark: string;
    moduleColorLight: string;
    cornerSize: number;
}

const QRSnakeForm: React.FC<QRBasicFormProps> = ({ initialState, onChange }) => {
    const [state, setState] = useState<QRSnakeFormState>(initialState);

    useEffect(() => {
        onChange(state);
    }, [state]);

    const handleInputChange = (key: keyof QRSnakeFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSliderChange = (key: keyof QRSnakeFormState) => (event: Event, value: number | number[]) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value as number,
        }));
    };

    const handleColorPickerChange = (key: keyof QRSnakeFormState) => async (color: string) => {
        setState((prevState) => ({
            ...prevState,
            [key]: color,
        }));
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Background Color"
                        value={state.backgroundColor}
                        onChange={handleColorPickerChange('backgroundColor')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.backgroundFill}
                                onChange={handleInputChange('backgroundFill')}
                            />
                        }
                        label="Background Fill"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Module Color Dark"
                        value={state.moduleColorDark}
                        type='dark'
                        onChange={handleColorPickerChange('moduleColorDark')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ColorPicker fullWidth margin='normal'
                        label="Module Color Light"
                        value={state.moduleColorLight}
                        type='light'
                        onChange={handleColorPickerChange('moduleColorLight')}
                    />
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <Typography>Corner Size</Typography>
                <Slider
                    value={state.cornerSize}
                    onChange={handleSliderChange('cornerSize')}
                    min={0} max={1} step={0.01}
                />
            </FormControl>
        </>
    );
};

export default QRSnakeForm;