import React from 'react';
import { Box } from '@mui/material';

interface ColorSwatchProps {
    color: string; // Hex color for the swatch
    onClick: (color: string) => void; // Function to call when swatch is clicked
    size?: number; // Optional size for the swatch
    borderColor?: string; // Optional border color for the swatch
}

const ColorSwatch: React.FC<ColorSwatchProps> = ({ color, onClick, size = 40, borderColor = 'transparent' }) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                backgroundColor: color,
                border: `2px solid ${borderColor}`,
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '0 0 5px rgba(0,0,0,0.2)',
            }}
            onClick={() => onClick(color)}
        />
    );
};

export default ColorSwatch;
