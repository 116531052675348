import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import ScreenHome from './ui/ScreenHome';
import ScreenResult from './ui/ScreenResult';
import BackgroundFX from './ui/BackgroundFX';
import Logo from './ui/Logo';
import ScreenSignUp from './ui/ScreenSignUp';
import ScreenDesigner from './ui/ScreenDesigner';
import { Box, Container, CircularProgress, Menu } from '@mui/material';
import ScreenAbout from './ui/ScreenAbout';
import SiteMenu from './ui/SiteMenu';
import ScreenNotFound from './ui/ScreenNotFound';
import ScreenGateway from './ui/ScreenGateway';

const theme = createTheme({
    typography: {
        fontFamily: '"Prompt", sans-serif',
    },
    palette: {
        background: {
            paper: '#FFFFFF',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: 'antiquewhite',
                },
            },
        },
    },
});

function App() {
    const [isAppReady, setIsAppReady] = useState(false);

    useEffect(() => {
        const initializeApp = async () => {
            // TODO init config from api here
            setIsAppReady(true);
        };
        initializeApp();
    }, []);

    if (!isAppReady) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    // error route just for testing not found when in local dev.
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <BackgroundFX />
                <SiteMenu />
                <Container>
                    <Routes>
                        <Route path="/" element={<ScreenHome />} />
                        <Route path="/result" element={<ScreenResult />} />
                        <Route path="/signup" element={<ScreenSignUp />} />
                        <Route path="/designer" element={<ScreenDesigner />} />
                        <Route path="/about" element={<ScreenAbout />} />
                        <Route path="/error" element={<ScreenNotFound />} />
                        <Route path="/:id/secure" element={<ScreenGateway />} />
                        <Route path='*' element={<ScreenNotFound />} />
                    </Routes>
                    <Box component="footer" textAlign="center" fontSize="12px" mt={4}>
                        Copywrite &copy; {new Date().getFullYear()} <Logo />
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default App;
