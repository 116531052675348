// BackgroundFX.tsx

import React, { useRef, useEffect, useState } from 'react';
import styles from './BackgroundFX.module.css'; // Import the styles
import { Boid, Boids } from '../fx/boids';

const BackgroundFX: React.FC = () => {

    const boidCount = 16;
    const boidSize = 40; // max size

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const drawBoid = (ctx: CanvasRenderingContext2D, boid: Boid, size: number) => {
        const front = { x: boid.x + size * Math.cos(boid.r), y: boid.y + size * Math.sin(boid.r) };
        const backLeft = { x: boid.x + size * Math.cos(boid.r + Math.PI * 0.8), y: boid.y + size * Math.sin(boid.r + Math.PI * 0.8) };
        const backRight = { x: boid.x + size * Math.cos(boid.r - Math.PI * 0.8), y: boid.y + size * Math.sin(boid.r - Math.PI * 0.8) };
        ctx.beginPath();
        ctx.fillStyle = "rgba(255,255,255,0.75)";
        ctx.moveTo(front.x, front.y);
        ctx.lineTo(backLeft.x, backLeft.y);
        ctx.lineTo(backRight.x, backRight.y);
        ctx.lineTo(front.x, front.y);
        ctx.closePath();
        ctx.fill();
    };

    const draw = (ctx: CanvasRenderingContext2D, t: number, w: number, h: number, mouseX: number, mouseY: number) => {
        ctx.clearRect(0, 0, w, h);
        //ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        //ctx.fillRect(0, 0, w, h);
        const size = Math.min(w / 800 * boidSize, boidSize); // responsive
        // Use mouseX and mouseY for dynamic interaction
        sim.boids.forEach(boid => { drawBoid(ctx, boid, size) });
    };

    let timeStart: number = -1;
    let sim: Boids;

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas == null) return;

        console.log('recreated fx');

        const context = canvas.getContext('2d')!;
        let frameId: number;
        timeStart = Date.now();
        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            if (sim) sim.resize(canvas.width, canvas.height);
        };
        resizeCanvas(); // initialize
        sim = new Boids(canvas.width, canvas.height);
        sim.init(boidCount);

        const handleMouseMove = (event: MouseEvent) => {
            //if (sim) sim.setTarget(event.clientX, event.clientY, 1);
            setMousePosition({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener('resize', resizeCanvas);
        window.addEventListener('mousemove', handleMouseMove);

        const handleMouseClick = (event: MouseEvent) => {
            if (sim) sim.setTarget(event.clientX, event.clientY, 1);
            setMousePosition({ x: event.clientX, y: event.clientY });
        };
        window.addEventListener('click', handleMouseClick);

        const render = () => {
            let time = Date.now() - timeStart;
            sim.animate();
            draw(context, time / 1000, context.canvas.width, context.canvas.height, mousePosition.x, mousePosition.y);
            frameId = window.requestAnimationFrame(render);
        };
        render();

        return () => {
            window.cancelAnimationFrame(frameId);
            window.removeEventListener('resize', resizeCanvas);
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return <canvas ref={canvasRef} className={styles.canvasBackground} />;
};

export default BackgroundFX;


